import Button from '@mui/joy/Button'
import Container from '@mui/joy/Container'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

interface DataProps {
  data: {
    backgroundImageUrl:string
    title: string
    description: string
    button: string
  }
}

interface TextPosition extends DataProps {
  position?: 'left' | 'center' | 'right'
}

interface StyledBoxProps {
  backgroundImageUrl?: string
}

const ImageBackground = styled('div', {
  shouldForwardProp: (propKey) => propKey !== 'backgroundImageUrl'
})<StyledBoxProps>(({ backgroundImageUrl }) => ({
  display: 'flex',
  backgroundImage: `url(${backgroundImageUrl})`,
  backgroundSize: 'cover',
  minHeight: '90vh'
}))

function FullImage({ position, data }: TextPosition) {

  let justifyContent = null
  switch (position) {
  case 'center':
    justifyContent = 'center'
    break
  case 'right':
    justifyContent = 'flex-end'
    break
  default:
    justifyContent = 'flex-start'
  }

  return (

    <ImageBackground backgroundImageUrl={data.backgroundImageUrl}>
      <Container
        maxWidth={'lg'}
        sx={{
          display: 'flex',
          justifyContent,
          alignItems: 'center'
        }}>
        <Stack
          direction={'row'}
          py={20}
          spacing={4}
          maxWidth={{
            xs: '100%',
            md: '70%',
            lg: '50%'
          }}
        >
          <div>
            <Typography
              color={'neutral'}
              fontWeight={'lg'}
              level={'h1'}
              gutterBottom>
              {data.title}
            </Typography>
            <Typography
              fontSize={'xl'}
              level={'body-md'}
              mb={3}>
              {data.description}
            </Typography>
            <Button
              size={'lg'}
              variant={'solid'}
            >
              {data.button}
            </Button>
          </div>
        </Stack>
      </Container>
    </ImageBackground>
  )
}
export default FullImage