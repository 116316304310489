import React from 'react'

import i18n from 'modules/i18n'

export default function AppProvider({ children }: React.PropsWithChildren): JSX.Element {

  React.useEffect(() => {
    Promise.all([
      i18n
    ]).then(() => {
      // TODO: i18n.changeLanguage(language)
    })
  }, [])

  return (
    <>
      {children}
    </>
  )
}
