import AboutUs from './about-us'
import LandingPage from './landing'

const appRouter = [{
  path: 'about-us',
  element: <AboutUs />
}, {
  path: '',
  element: <LandingPage />
}]

export default appRouter
