import Container from '@mui/joy/Container'
import Divider from '@mui/joy/Divider'
import Grid from '@mui/joy/Grid'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

import { DATA } from './service-description-data'

function ServiceDescription() {

  return (

    <Container
      maxWidth={'lg'}>
      <Grid
        flexDirection={'row'}
        py={3}>
        <Typography
          fontWeight={'lg'}
          level={'h1'}>
          {DATA.title}
        </Typography>
        <Stack
          sx={{
            py: 3
          }}>
          <Divider
          />
        </Stack>
        <Grid
          direction={'row'}
          justifyContent={'space-between'}
          pt={3}
          container>
          <Grid>
            <Typography
              level={'title-md'}
              textAlign={'left'}
              textTransform={'uppercase'}>
              {DATA.service}
            </Typography>
          </Grid>
          <Grid
            justifyContent={'flex-end'}
            maxWidth={800}
            mr={0.1}
            textAlign={'left'}>
            <Typography
              fontWeight={'sm'}
              level={'h1'}
              variant={'plain'}
              gutterBottom>
              {DATA.subtitle}
            </Typography>
            <Grid
              direction={'row'}
              justifyContent={'space-between'}
              maxWidth={800}
              pt={3}
              sx={{ flexGrow: 1, gap: 2 }}
              container>
              {DATA.items.map((item) =>
                <Grid
                  key={item.id}
                  maxWidth={350}
                  container>
                  <Typography
                    fontWeight={'md'}
                    level={'h3'}
                    lineHeight={'normal'}
                    gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography
                    level={'body-md'}
                    gutterBottom>
                    {item.description}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ServiceDescription