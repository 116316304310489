import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

interface DataProps {
  data: {
    title: string
    subtitle: string
  }
}

function Title({ data }: DataProps) {

  return (
    <Stack
      flexDirection={'column'}
      py={2}
      width={'100%'}
    >
      <Typography
        fontWeight={'lg'}
        level={'h1'}
        textAlign={'center'}
        gutterBottom>
        {data.title}
      </Typography>
      <Typography
        fontSize={'xl'}
        fontWeight={'sm'}
        textAlign={'center'}>
        {data.subtitle}
      </Typography>
    </Stack>
  )
}

export default Title