import { styled } from '@mui/joy'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Container from '@mui/joy/Container'
import Grid from '@mui/joy/Grid'
import Typography from '@mui/joy/Typography'

import { Photo } from 'types/photo.type'
import isEmpty from 'utils/isEmpty'

interface DataProps {
  data: {
    title: string
    subtitle: string
    description: string
    contact: string
    photo?: Photo
  }
}
interface BannerProps extends DataProps {
  switchedImage?: boolean
}

const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.block.dark,
  width: '100%'
}))

function Banner({ switchedImage = false, data }: BannerProps) {

  return (
    <BoxStyled>
      <Container
        maxWidth={'lg'}>
        <Grid
          alignItems={'center'}
          flexDirection={switchedImage ? 'row-reverse' : 'row'}
          flexGrow={1}
          gap={1}
          height={500}
          justifyContent={ data.photo?.src ? 'space-between':'center'}
          container>
          <Grid
            px={2}
            textAlign={data.photo?.src? 'left' : 'center'}
            maxWidth={{
              xs: '100%',
              md: isEmpty(data.photo) ? '70%' : '50%'
            }}>
            <Typography
              color={'neutral'}
              fontWeight={'lg'}
              level={'h1'}>
              {data.title}
            </Typography>
            <Typography
              level={'title-lg'}
              pt={3}>
              {data.subtitle}
            </Typography>
            <Typography
              level={'body-md'}
              py={3}>
              {data.description}
            </Typography>
            <Button
              color={'neutral'}
              size={'lg'}
              variant={'outlined'}>
              {data.contact}
            </Button>
          </Grid>
          {!isEmpty(data.photo)
            && <Grid
              maxWidth={{
                xs: '100%',
                md: '50%'
              }}>
              <img
                alt={data.photo?.title}
                loading={'lazy'}
                src={data.photo?.src}
                style={{
                  width: '100%',
                  objectFit: 'cover'
                }} />
            </Grid>
          }
        </Grid>
      </Container>
    </BoxStyled>
  )
}

export default Banner