export const DATA = {
  backgroundImageUrl: process.env.PUBLIC_URL + '/bg.jpg',
  title: 'PHOTOGRAPHY THEME',
  subtitle: 'Trusted by 90,0000 + happy customer',
  description: 'A gray cat slinks past a wooden house. There’s something a little intimidating attempting to describe.',
  image: {
    id: 'signature',
    title: 'signature',
    src: 'signature.png'
  }
}