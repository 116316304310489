import Card from '@mui/joy/Card'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

interface CardData {
  data: {
    id: string
    title: string
    description: string
  }
}

export default function ColumnCard({ data }: CardData) {

  return (

    <Stack
      direction={'row'}
      justifyContent={'center'}
      m={4}
    >
      <Card>
        <Stack
          alignItems={'center'}
        >
          <Typography
            level={'title-lg'}
            textAlign={'center'}
            gutterBottom
          >
            {data.title}
          </Typography>
          <Typography
            level={'title-sm'}
            textAlign={'center'}
          >
            {data.description}
          </Typography>
        </Stack>
      </Card>
    </Stack>

  )
}
