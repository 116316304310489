import Card from '@mui/joy/Card'
import Divider from '@mui/joy/Divider'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

import type { Photo } from 'types/photo.type'

interface OverlayCardProps {
  title: string
  subtitle: string
  description: string
  image: Photo
}
export default function OverlayCard({
  title, subtitle, description, image
}: OverlayCardProps) {

  return (
    <Stack
      direction={'row'}
      justifyContent={'center'}>
      <Card
        sx={{
          border: 0,
          borderRadius: 0,
          padding: 1
        }}>
        <Stack
          alignItems={'center'}
          px={{
            xs: 2,
            md: 4
          }}
          py={{
            xs: 4,
            md: 7
          }}
          sx={{
            border: '1px solid #222'
          }}>
          <Typography
            level={'body-xs'}
            textAlign={'center'}
            variant={'plain'}
          >
            {subtitle}
          </Typography>
          <Typography
            level={'h2'}
            textAlign={'center'}
          >
            {title}
          </Typography>
          <Stack
            py={3}
            width={40}>
            <Divider
              sx={{
                borderTop: '2px solid #222'
              }} />
          </Stack>
          <Stack
            pb={3}>
            <Typography
              level={'title-sm'}
              textAlign={'center'}
              sx={{
                fontStyle: 'italic'
              }}
            >
              {description}
            </Typography>
          </Stack>
          <img
            alt={image.title}
            src={image.src}
            style={{
              maxWidth: 195
            }} />
        </Stack>

      </Card>
    </Stack>
  )
}