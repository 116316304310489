import { Link } from 'react-router-dom'
import { Container, Divider, IconButton, Stack } from '@mui/joy'
import { Facebook } from 'lucide-react'
import { Instagram } from 'lucide-react'

import ModeToggle from 'components/mode-toggle'

export default function Header() {

  return (
    <Container maxWidth={'lg'}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        py={1}>
        <Stack
          direction={'row'}
          gap={2}
        >
          <IconButton
            component={Link}
            rel={'noopener noreferrer'}
            target={'_blank'}
            to={'https://www.facebook.com/slit24.photo/'}>
            <Facebook />
          </IconButton>
          <IconButton
            component={Link}
            rel={'noopener noreferrer'}
            target={'_blank'}
            to={'https://www.instagram.com/slit24.photo/'}>
            <Instagram />
          </IconButton>
        </Stack>
        <Stack
          direction={'row'}>
          <ModeToggle />
        </Stack>
      </Stack>
      <Divider />
    </Container>
  )
}
