import Banner from 'components/blocks/banner'
import { DATA1 as BANNER_DATA1, DATA2 as BANNER_DATA2 } from 'components/blocks/banner/banner-data'
import Columns from 'components/blocks/columns'
import { DATA as COLUMNS_DATA } from 'components/blocks/columns/columns-data'
import Faq from 'components/blocks/faq'
import { DATA as FAQ_DATA } from 'components/blocks/faq/faq-data'
import FullImage from 'components/blocks/full-image'
import { DATA as FULL_IMAGE_DATA } from 'components/blocks/full-image/full-image-data'
import GalleryGrid from 'components/blocks/gallery-grid'
import GalleryLine from 'components/blocks/gallery-line'
import OverlayWithCard from 'components/blocks/overlay-with-card'
import ServiceDescription from 'components/blocks/service-description'
import Title from 'components/blocks/title'
import { DATA as TITLE_DATA } from 'components/blocks/title/title-data'

export default function LandingPage() {

  return (

    <>
      <FullImage data={FULL_IMAGE_DATA} />
      <OverlayWithCard />
      <GalleryGrid />
      <GalleryLine />
      <ServiceDescription />
      <Banner data={BANNER_DATA1} />
      <Banner
        data={BANNER_DATA2}
        switchedImage
      />
      <Banner data={BANNER_DATA1} />
      <Faq data={FAQ_DATA}/>
      <Title data={TITLE_DATA} />
      <Columns data={COLUMNS_DATA} />
    </>
  )
}
