import Stack from '@mui/joy/Stack'

import OverlayCard from './overlay-card'
import { DATA } from './overlay-with-card-data'

export default function OverlayWithCard() {

  return (
    <Stack
      p={{
        xs: 3,
        sm: 7,
        md: 15
      }}
      sx={{
        backgroundImage: `url(${DATA.backgroundImageUrl})`,
        backgroundSize: 'cover'
      }}>
      <OverlayCard
        description={DATA.description}
        image={DATA.image}
        subtitle={DATA.subtitle}
        title={DATA.title}
      />
    </Stack>

  )
}