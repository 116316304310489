export const DATA = [
  {
    id: 'c1',
    title: 'Time Management',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c2',
    title: 'Brand Management',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c3',
    title: 'Public Relations',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c4',
    title: 'Risk Management',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c5',
    title: 'Time Management',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c6',
    title: 'Brand Management',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c7',
    title: 'Public Relations',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c8',
    title: 'Risk Management',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c9',
    title: 'Time Management',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c10',
    title: 'Brand Management',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c11',
    title: 'Public Relations',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  },
  {
    id: 'c12',
    title: 'Risk Management',
    description: 'Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus.'
  }

]