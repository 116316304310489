export const DATA1 = {
  title: 'Every frame tells a story, inviting you to embark on a journey.',
  subtitle: 'Compose photography at Its finest',
  description: 'From sweeping landscapes that transport you to distant realms, to intimate portraits that reveal the depth of human emotions, Compose Photography transcends the ordinary and delves into the extraordinary.',
  contact: 'CONTACT ME',
  photo: {
    id: 'l2',
    src: 'https://lh3.googleusercontent.com/u/1/drive-viewer/AEYmBYRlY9nZhqf7u6j6mjsljinnTbq7ibGNNWfkNUOMltD9siNad2NqBa8rjCiqGsIlzoVTyN0DKEa0kXqFPTcChR-9YFddgg=w480',
    title: 'LANDSCAPE'
  }

}

export const DATA2 = {
  title: 'Every frame tells a story, inviting you to embark on a journey.',
  subtitle: 'Compose photography at Its finest',
  description: 'From sweeping landscapes that transport you to distant realms, to intimate portraits that reveal the depth of human emotions, Compose Photography transcends the ordinary and delves into the extraordinary.',
  contact: 'CONTACT ME'
}

