import Grid from '@mui/joy/Grid'

import FaqItem from './faq-item'

interface FaqProps{
  data: {
    id: string
    title: string
    description: string
  }[]
}

export default function Faq({ data }: FaqProps) {

  return (

    <Grid
      display={'flex'}
      flexDirection={'row'}
      flexGrow={1}
      justifyContent={'flex-end'}
      maxHeight={'90vh'}
      py={2}
      width={'100%'}
      container>

      {data.map((dataItem) =>
        <Grid
          key={dataItem.id}
          py={2}
          sm={5}
          width={'50%'}
        >
          <FaqItem
            data={dataItem}
          />
        </Grid>
      )}
    </Grid>
  )
}
