import Accordion from '@mui/joy/Accordion'
import AccordionDetails from '@mui/joy/AccordionDetails'
import AccordionSummary from '@mui/joy/AccordionSummary'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

interface FaqItemData {
  data: {
    id: string
    title: string
    description: string
  }
}

export default function FaqItem({ data }: FaqItemData) {

  return (

    <Stack
      justifyContent={'center'}
      maxWidth={500}
    >
      <Accordion>
        <AccordionSummary>
          <Typography
            fontSize={'xl'}
            fontWeight={'md'}
            level={'h3'}
          >
            {data.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            level={'body-sm'}>
            {data.description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>

  )
}