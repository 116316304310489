import { Grid } from '@mui/joy'

import { PHOTOS } from '../gallery-grid/gallery-grid-data'

function GalleryLine() {

  return (
    <Grid
      flexDirection={'row'}
      container>
      {PHOTOS.slice(0, 7).map((photo) =>
        <Grid
          key={photo.id}
          flexDirection={'row'}
          md={12/5}
          sm={3}
          xs={6}
          lg>
          <img
            key={photo.id}
            alt={photo.title}
            loading={'lazy'}
            src={photo.src}
            style={{
              width: '100%',
              height: 250,
              objectFit: 'cover'
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default GalleryLine