import Grid from '@mui/joy/Grid'

import { DATA } from 'components/blocks/columns/columns-data'

import ColumnCard from './column-card'

interface ColumnsProps {
  data: {
    id: string
    title: string
    description: string
  }[]
}

export default function Columns({ data }: ColumnsProps) {

  return (

    <Grid
      flexDirection={'row'}
      justifyContent={'center'}
      container>
      {DATA.map((data) =>
        <Grid
          key={data.id}
          flexDirection={'row'}
          md={3}
          sm={6}
          xs={12}
        >
          <ColumnCard data={data}/>
        </Grid>
      )}
    </Grid>
  )

}