import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import CssBaseline from '@mui/joy/CssBaseline'

import AppPage, { appRouter } from 'app'
import ThemeProvider from 'modules/theme'

import AppProvider from './app.provider'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppPage/>,
    children: appRouter
  }
])

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <CssBaseline />
      <AppProvider>
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <RouterProvider router={router} />
        </div>
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
