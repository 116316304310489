import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/joy'
import Button from '@mui/joy/Button'
import Container from '@mui/joy/Container'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

export default function Footer() {
  const { t, i18n } = useTranslation()
  const appName = process.env.REACT_APP_NAME
  console.log('appName', appName)
  const languages: Record<string, string> = {
    en: 'English',
    nl: 'Nederlands',
    de: 'Deutsch',
    sr: 'Srpski'
  }
  console.log('i18n.language', i18n.language)
  const date = new Date()
  return (
    <Container maxWidth={'lg'}>
      <Divider />
      <Stack
        alignItems={'center'}
        gap={1}
        py={2}>
        <Stack
          direction={'row'}
          gap={1}>
          {Object.keys(languages).map((key: string) => (
            <Button
              key={`i18n-${key}`}
              disabled={key === i18n.language}
              onClick={() => i18n.changeLanguage(key)}
              size={'sm'}
              variant={'plain'}
            >
              {languages[key]}
            </Button>
          ))}
        </Stack>

        <Stack>
          <Typography level={'body-sm'}>
            {t('Copyright © {{ app }} {{1}}.', { app: appName , 1: date.getFullYear() })}
          </Typography>
        </Stack>
      </Stack>
    </Container>
  )
}
