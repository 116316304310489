import React from 'react'
import Box from '@mui/joy/Box'

export default function AboutUsPage() {

  return (
    <Box >
      About us
    </Box>
  )
}
