export const DATA = {
  title: 'Travel Together at Paris',
  subtitle: 'Embark on mesmerizing visual expedition with "Ethereal Wanderlust", a captivating photography album that transports you to the realms of enchantment and beauty. This meticulously curated collection of images captures the essence of fleeting moments, evoking a sense of wonder and igniting your imagination.',
  service: 'SERVICES',
  items: [{
    id: 't1',
    title: 'Our weeding and engagment package',
    description: 'Our team specializes in capturing the love,joy and emotion of your special day. From intimate elopements to grand celebrations, we will document every precious moment, ensuring that your love story is beautifully preserved.'
  },
  {
    id: 't2',
    title: 'Celebrate the beauty and individuality of yourself',
    description: 'Whether it`s a professional headshot, a familly portrait, or a creative concept, we will artistically capture your personality, creating stunning images that evoke a sense of authenticity and connection.'
  }
  ]
}