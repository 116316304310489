import Container from '@mui/joy/Container'
import Grid from '@mui/joy/Grid'

import { Photo } from 'types/photo.type'

import { PHOTOS } from './gallery-grid-data'

interface PhotoGrid {
  id: string
  items: Photo[]
}

export default function GalleryGrid() {
  const galleryGrid: PhotoGrid[] = []

  let gridRow = 0
  const numberOfRows = 5
  PHOTOS.slice(0, numberOfRows * 4).forEach((photo,idx) => {
    if (!galleryGrid[gridRow]) {
      galleryGrid[gridRow] = {
        id: `grid-${idx}`,
        items: []
      }
    }

    galleryGrid[gridRow].items.push(photo)

    gridRow += 1
    if (gridRow === numberOfRows) {
      gridRow = 0
    }
  })

  return (

    <Container maxWidth={'lg'}>
      <Grid
        flexDirection={'row'}
        gap={4}
        py={4}
        container>
        {galleryGrid.map((grid) => (
          <Grid
            key={grid.id}
            flexDirection={'column'}
            gap={4}
            container
            sm
            xs
          >
            {grid.items.map((photo) =>
              <img
                key={photo.id}
                alt={photo.title}
                loading={'lazy'}
                src={photo.src}
                style={{
                  width: '100%'
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}